import * as z from 'zod';

import { AuthZeroId, CompanyId, TeamId, UserId } from './BrandedIds';
import { CompanyReportType } from './CompanyReport';
import { TeamPermissionsActions } from './team/TeamPermissions';
import { zodBrandedUuid, zodDateOrString } from './utils/Zod';

export const UserRoles = z.object({ admin: z.boolean().default(false) }).strict();
export type UserRoles = z.infer<typeof UserRoles>;

export const UserName = z.object({
  firstName: z.string(),
  lastName: z.string(),
});
export type UserName = z.infer<typeof UserName>;

export const User = z.object({
  id: zodBrandedUuid<UserId>(),
  email: z.string(),
  name: UserName.optional(), // Followup: require this after names have been backfilled
  authZeroId: zodBrandedUuid<AuthZeroId>().optional(),
  roles: UserRoles,
  createdAt: zodDateOrString,
  updatedAt: zodDateOrString,
  authZeroConnectionNames: z.array(z.string()),
});
export type User = z.infer<typeof User>;

export const DEFAULT_USER_PERMISSIONS = { admin: false };
export const UserPermissions = z.object({ admin: z.boolean() });
export type UserPermissions = z.infer<typeof UserPermissions>;

export const UserLastSeenAt = z.object({ taskPage: zodDateOrString.optional() });
export type UserLastSeenAt = z.infer<typeof UserLastSeenAt>;

export const CompanyRole = z.enum(['standard', 'admin']);
export type CompanyRole = z.infer<typeof CompanyRole>;

// This will soon 'extend' TeamPermissionsActions
// An example would be to replace checking "userToCompany.permissions.admin" when we check if they can access the "Settings" tab,
// Instead, add a key here for `canEditSettings` if it isn't part of TeamPermissions
const CompanyPermissionsActions = TeamPermissionsActions;
export type CompanyPermissionsActions = z.infer<typeof CompanyPermissionsActions>;

export const CompanyPermissions = z.object({
  actions: CompanyPermissionsActions,
});
export type CompanyPermissions = z.infer<typeof CompanyPermissions>;

export const DEFAULT_COMPANY_PERMISSIONS: CompanyPermissions = {
  actions: {
    createPayments: true,
    createDisbursements: true,
    createFees: true,
    createPayoffStatements: true,
    createLoanDocuments: true,
    createDraws: true,
    createWorkoutPlans: true,
    updateBorrowerDetails: true,
    createReports: true,
    createDefaultInterest: true,
    updateMaturityDate: true,
    updateInterestRate: true,
  },
};

export const UserPreferences = z.object({
  views: z
    .object({
      loanDashboardDefault: z.string().optional(),
      transactionDashboardDefault: z.string().optional(),
    })
    .optional(),
  favoriteReports: z
    .object({
      oneTime: z.array(z.nativeEnum(CompanyReportType)).optional(),
      recurring: z.array(z.nativeEnum(CompanyReportType)).optional(),
    })
    .optional(),
});
export type UserPreferences = z.infer<typeof UserPreferences>;

const UserByCompany = z.object({
  companyId: zodBrandedUuid<CompanyId>(),
  permissions: UserPermissions, // TODO: Remove. This is replaced by `companyRole`
  preferences: UserPreferences,
  lastSeenAt: UserLastSeenAt,
  companyRole: CompanyRole,
  teams: zodBrandedUuid<TeamId>().array(),
});
export type UserByCompany = z.infer<typeof UserByCompany>;

export const FullUser = User.extend({
  contextByCompany: UserByCompany.array(),
});
export type FullUser = z.infer<typeof FullUser>;

export const FullUserWithCompanyPermissions = User.extend({
  contextByCompany: UserByCompany.extend({
    companyPermissions: CompanyPermissions,
  }).array(),
});
export type FullUserWithCompanyPermissions = z.infer<typeof FullUserWithCompanyPermissions>;
