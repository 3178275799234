import * as z from 'zod';

import { ApiKeyId, CompanyId, ExternalEntityId, LoanImportId, LoanImportRowId, LosId, UserId } from '../BrandedIds';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { BorrowerDetailImportFields } from '../validations/loan/createLoanFields/BorrowerFields';
import { CreateLoanRow } from '../validations/loan/CreateLoanRow';
import { ImportLoanAddressFields } from '../validations/loan/sharedFields/AddressFields';
import { MERSImportFields } from '../validations/loan/sharedFields/MERSImportFields';
import { UpdateLoanRow } from '../validations/loan/UpdateLoanRow';
import { CreateTransactionRowData } from './LoanTransaction';
import { TransferLoanRowData } from './LoanTransfer';

export const ImportStatus = z.enum(['PENDING', 'COMPLETED', 'FAILED']);
export type ImportStatus = z.infer<typeof ImportStatus>;

export const ImportType = z.enum([
  'create',
  'transfer',
  'transferRevision',
  'createTransaction',
  'update',
  'recordPayment',
  'constructionToPermanent',
  'recordEscrowDiscursement',
  'recordDrawDisbursement',
  'updateBorrowerAchDetails',
  'updateIdsAndRemittanceData',
  'managePointOfContact',
]);
export type ImportType = z.infer<typeof ImportType>;

export const ImportSource = z.enum(['csv', 'api', 'csvV2']);
export type ImportSource = z.infer<typeof ImportSource>;

export const ImportFile = z.enum(['csv', 'xlsx']);
export type ImportFile = z.infer<typeof ImportFile>;

export interface LoanImport {
  id: LoanImportId;
  companyId: CompanyId;
  userId: UserId;
  status: ImportStatus;
  spaceId?: string;
  fileName: string;
  totalRows: number;
  createdAt: Date;
  updatedAt: Date;
  type: ImportType;
  createdRowsUrl?: string;
  // TODO LESLIE 11/3: Drop default in prisma after migration
  source: ImportSource;
  apiKeyId?: ApiKeyId;
  apiRequestBody?: string;
}

export interface LoanImportRow {
  id: LoanImportRowId;
  loanImportId: LoanImportId;
  rowNumber: number;
  losId: LosId;
  status: ImportStatus;
  reason?: string;
  createdAt: Date;
  updatedAt: Date;
}

// THINGS TO DO WITH THE RAW IMPORT ROW
// First, take the initial object parser
// from the spreadsheet uploader or API — ie, CreateLoanRow.
// Then...
// * Turn date strings into Date
// * Turn ids into branded IDs
// * Trim/strip strings (in the ImportRowsData response below)

export const ImportRow = z
  .object({
    ...CreateLoanRow.shape,
    loanId: z.string().transform((id) => id as LosId),
    fundingDate: zodDateOrString,
    loanClosingDate: zodDateOrString.optional(),
    repurchasedDate: zodDateOrString.optional(), // Deprecated for incomingTransferEffectiveDate
    incomingTransferEffectiveDate: zodDateOrString.optional(),
    acquisitionDate: zodDateOrString.optional(),
    loanMaturityDate: zodDateOrString,
    firstCollectedPaymentDate: zodDateOrString.optional(),
    firstPaymentDate: zodDateOrString,
    entityCompanyId: z
      .string()
      .transform((id) => id as ExternalEntityId)
      .optional(),
    escrow1PolicyStart: zodDateOrString.optional(),
    escrow1PolicyEnd: zodDateOrString.optional(),
    escrow2PolicyStart: zodDateOrString.optional(),
    escrow2PolicyEnd: zodDateOrString.optional(),
    escrow3PolicyStart: zodDateOrString.optional(),
    escrow3PolicyEnd: zodDateOrString.optional(),
    drawExpirationDate: zodDateOrString.optional(),
    repayBeginDate: zodDateOrString.optional(),
    mers: MERSImportFields.optional(),
    noteDate: zodDateOrString.optional(),
    primaryBorrowerDetails: BorrowerDetailImportFields.optional(),
    coBorrower1Details: BorrowerDetailImportFields.optional(),
    coBorrower2Details: BorrowerDetailImportFields.optional(),
    coBorrower3Details: BorrowerDetailImportFields.optional(),
    coBorrower4Details: BorrowerDetailImportFields.optional(),
    coBorrower5Details: BorrowerDetailImportFields.optional(),
    coBorrower6Details: BorrowerDetailImportFields.optional(),
    coBorrower7Details: BorrowerDetailImportFields.optional(),
    coBorrower8Details: BorrowerDetailImportFields.optional(),
    coBorrower9Details: BorrowerDetailImportFields.optional(),
    ...ImportLoanAddressFields.shape,
    interestOnlyEndDate: zodDateOrString.optional(),
  })
  .strict();

export type ImportRow = z.infer<typeof ImportRow>;

const LoanImportBase = z.object({
  id: zodBrandedUuid<LoanImportId>(),
  companyId: zodBrandedUuid<CompanyId>(),
  userId: zodBrandedUuid<UserId>(),
  status: ImportStatus,
  fileName: z.string(),
  totalRows: z.number().int(),
  createdAt: z.date(),
  updatedAt: z.date(),
  source: ImportSource,
  apiKeyId: zodBrandedUuid<ApiKeyId>().optional(),
});
const LoanImportCreate = LoanImportBase.extend({
  type: z.literal(ImportType.Values.create),
  apiRequestBody: ImportRow.array().optional(),
});

const LoanImportTransfer = LoanImportBase.extend({
  type: z.literal(ImportType.Values.transfer),
  apiRequestBody: TransferLoanRowData(false).array().optional(),
});

const LoanImportTransaction = LoanImportBase.extend({
  type: z.literal(ImportType.Values.createTransaction),
  apiRequestBody: CreateTransactionRowData.array().optional(),
});

const LoanImportUpdate = LoanImportBase.extend({
  type: z.literal(ImportType.Values.update),
  apiRequestBody: UpdateLoanRow.array().optional(),
});

export const LoanImportTyped = z.discriminatedUnion('type', [
  LoanImportCreate,
  LoanImportTransfer,
  LoanImportTransaction,
  LoanImportUpdate,
]);
