import { DateTime } from 'luxon';
import * as z from 'zod';

import { Address } from '../Address';
import {
  AuthorizedContactId,
  BorrowerId,
  CompanyId,
  EntityId,
  LoanId,
  LoanOwnerId,
  LosId,
  PoolId,
  UserId,
} from '../BrandedIds';
import { IndexRateType } from '../indexRate';
import { OptionalPhoneNumber } from '../Phone';
import { zodDateOrString } from '../utils/Zod';
import { OptionalEmailString } from '../validations/email';
import { NonNegativeInteger, NonNegativeMonetaryValue } from '../validations/fields';
import { ConstructionConfig } from './LoanSnapshot';
import { MERS } from './MERS';
import { TaxDetails } from './TaxDetails';

export interface Loan {
  id: LoanId;
  company: CompanyId;
  address: Address;
  configuration: LoanConfiguration;
  originalAmount: number;
  raw: object;
  losId: LosId;
  poolId?: PoolId;
  previousServicerId?: string;
  capitalPartnerId?: string;
  fullId?: string;
  otherId1?: string;
  otherId2?: string;
  otherId3?: string;
  ownerId?: LoanOwnerId;
  noteflowId?: string;
  primaryBorrower: BorrowerId;
  additionalBorrowers: BorrowerId[];
  entityId?: EntityId;
  authorizedContacts?: AuthorizedContactId[];
  externalIdHistory: {
    poolId: ExternalIdHistory[];
    previousServicerId: ExternalIdHistory[];
    capitalPartnerId: ExternalIdHistory[];
    fullId: ExternalIdHistory[];
    otherId1: ExternalIdHistory[];
    otherId2: ExternalIdHistory[];
    otherId3: ExternalIdHistory[];
    ownerId: ExternalIdHistory[];
  };
  pointOfContact?: UserId;
  createdAt: Date;
  updatedAt: Date;
}

export interface ExternalIdHistory {
  startDate: Date;
  id: string;
  value: string;
  soldDate?: Date;
}

export const InterestOnlyType = z.enum(['30-360', 'actual-365', 'actual-360']);
export type InterestOnlyType = z.infer<typeof InterestOnlyType>;

export const InterestOnlyConfig = z.object({
  loanTerm: z.number(), // number of months
  type: InterestOnlyType,
});
export type InterestOnlyConfig = z.infer<typeof InterestOnlyConfig>;

export const DrawConfiguration = z.object({
  drawsEnabled: z.boolean(),
  maxLineOfCredit: z.number(),
  drawTerm: NonNegativeInteger.optional(), // months borrower can draw down more capital
  drawExpirationDate: zodDateOrString.optional(), // date at which ability to draw is frozen
  repayBeginDate: zodDateOrString.optional(), // date at which principal payments begin and the loan starts amortizing
});
export type DrawConfiguration = z.infer<typeof DrawConfiguration>;

export const BuydownType = z.enum(['3/2/1', '2/1', '1/1', '1/0']);
export type BuydownType = z.infer<typeof BuydownType>;

export const NoteholderType = z.enum(['investor', 'servicer', 'thirdParty']);
export type NoteholderType = z.infer<typeof NoteholderType>;

export const ProductType = z
  .enum([
    'fha',
    'va',
    'usda',
    'pih',
    'conv',
    'heloc',
    'bridge', // Vontive specific not a government backed loan, aka hard money
    'businessPurpose', // Vontive specific
    'jumbo',
  ])
  .optional();
export type ProductType = z.infer<typeof ProductType>;

// active: in active construction, I/O interest mode
// permanent: construction is done, no more draws, 30 year fixed mode
export const ConstructionToPermanentStatus = z.enum(['active', 'permanent']);
export type ConstructionToPermanentStatus = z.infer<typeof ConstructionToPermanentStatus>;

export const ConstructionLoanType = z.enum(['constructionOnly', 'constructionToPermanent']);
export type ConstructionLoanType = z.infer<typeof ConstructionLoanType>;

export const PreviousServicerData = z.object({
  lenderName: z.string().optional(),
  lenderPhone: OptionalPhoneNumber,
  lenderEmail: OptionalEmailString,
  purchaseDate: zodDateOrString.optional(),
  purchasedEscrowBalance: z.number().optional(),
  purchasedReserveBalance: z.number().optional(),
});
export type PreviousServicerData = z.infer<typeof PreviousServicerData>;

export const PrepaymentPenaltyPlanType = z.enum(['percent', 'fixed']);

export const LoanPrepaymentPenaltyFee = z.object({
  flag: z.boolean(), // indicates whether to override the company setting
  expirationMonths: z.number().positive().int().optional(),
  planDescription: z.string().optional(),
  planType: PrepaymentPenaltyPlanType.optional(),
});
export type LoanPrepaymentPenaltyFee = z.infer<typeof LoanPrepaymentPenaltyFee>;

export const LoanLateFeeType = z.enum(['percent', 'fixed']);

const LoanLateFee = z.object({
  type: LoanLateFeeType.optional(),
  decimalCharge: z.number().nonnegative().optional(),
  minAmount: NonNegativeMonetaryValue.optional(),
  maxAmount: NonNegativeMonetaryValue.optional(),
});
export type LoanLateFee = z.infer<typeof LoanLateFee>;

/**
 * Overrides company fee settings
 */
export const LoanFees = z.object({
  late: z.number().nonnegative().optional(), // decimal value. Overrides company fee setting
  lateCharge: LoanLateFee.optional(), // TODO followupmigrate to this new lateCharge field
  prepaymentPenalty: LoanPrepaymentPenaltyFee.optional(),
});
export type LoanFees = z.infer<typeof LoanFees>;

// WHEDA: these details are on the loan right now but should probably move elsewhere
// See: https://github.com/willowservicing/willow/issues/6043#issuecomment-2433144282
export const LoanInvestorName = z.enum(['FANNIE_MAE', 'FREDDIE_MAC']);
export type LoanInvestorName = z.infer<typeof LoanInvestorName>;
export const LoanInvestor = z.object({
  investorName: LoanInvestorName.optional(),
  investorLoanId: z.string().optional(),
  investorMasterId: z.string().optional(),
  ginnieMaeLoanId: z.string().optional(),
  fhaCaseNumber: z.string().optional(),
  mortgageIdentificationNumber: z.string().optional(), // TODO followup delete
  mersRegistrationDate: zodDateOrString.optional(), // TODO followup delete
  mers: MERS.optional(),
  noteholder: NoteholderType.optional(),
  documentCustodian: z.string().optional(),
});
export type LoanInvestor = z.infer<typeof LoanInvestor>;

// Number of payments per year
export const PaymentFrequency = z.union([
  z.literal(1), // Annual
  z.literal(12), // Monthly
]);
export type PaymentFrequency = z.infer<typeof PaymentFrequency>;

const BaseLoan = z.object({
  loanTerm: z.number(), // number of months
  fundingDate: zodDateOrString,
  loanClosingDate: zodDateOrString.optional(), // Date the loan closed
  firstDueDate: zodDateOrString, // First payment date on the loan
  firstCollectedPaymentDate: zodDateOrString, // first payment collected in Willow
  /**
   * @deprecated
   * use incomingTransferEffectiveDate instead. left in for backwards compatibility with createLoan definition
   */
  repurchasedDate: zodDateOrString.optional(),
  incomingTransferEffectiveDate: zodDateOrString.optional(), // date the lender is responsible for servicing the loan
  minMonthlyPayment: NonNegativeMonetaryValue.optional(), // min amount that can be paid to avoid late fees
  isEscrowed: z.boolean().optional(),
  interestOnlyConfig: InterestOnlyConfig.optional(),
  gracePeriod: z.number().optional(),
  purpose: z.enum(['purchase', 'refinance']).optional(),
  occupancyType: z.enum(['primary', 'second', 'investment']).optional(),
  legalDescription: z.string().optional(),
  numberOfProperties: z.number().optional(),
  hasInterestReserve: z.boolean(),
  reserveBalanceFundedByThirdParty: z.boolean().optional(),
  buydownType: BuydownType.optional(),
  // Original maturity date on upload, can be changed on some loans
  initialMaturityDate: zodDateOrString,
  initialDiscountPointsAmount: z.number().optional(),
  initialBeginningOfYearPrincipalBalance: z.number().optional(),
  initialYtdInterestPaid: z.number().optional(),

  acquisitionDate: zodDateOrString.optional(),

  previousServicer: PreviousServicerData.optional(),
  brokerName: z.string().optional(),

  fees: LoanFees.optional(),

  investor: LoanInvestor.optional(),
  lienPosition: NonNegativeInteger.optional(), // order of claim priority in the event of default/foreclosure
  noteDate: zodDateOrString.optional(), // date the borrower and original lender signed at closing (most of the time is the same as the loan closing date)
  qualifiedMortgage: z.boolean().optional(), // indicates that a borrower has been deemed able to meet their mortgage obligations
  paymentFrequency: PaymentFrequency,
  taxDetails: TaxDetails.optional(),
});

export const LoanConstructionConfig = z.object({
  constructionLoanType: ConstructionLoanType,
  dutch: z.boolean(), // true = dutch (accrue interest on the max line of credit), false = non-dutch (accrue interest on the drawn principal)
  fixedPeriodPrincipalTotal: z.number().optional(),
});

export type LoanConstructionConfig = z.infer<typeof LoanConstructionConfig>;

export const FixedLoanProductType = z.enum(['fha', 'va', 'usda', 'pih', 'conv', 'bridge', 'businessPurpose', 'jumbo']);

export type FixedLoanProductType = z.infer<typeof FixedLoanProductType>;

export const FixedLoan = BaseLoan.extend({
  paymentType: z.literal('fixed'),
  productType: FixedLoanProductType.optional(),
  annualInterestRate: z.number(), // represented as 0.035 for 3.5%
  constructionConfig: LoanConstructionConfig.optional(),
  drawConfiguration: DrawConfiguration.optional(),
});
export type FixedLoan = z.infer<typeof FixedLoan>;

export const FixedConstructionLoan = FixedLoan.required({
  constructionConfig: true,
  drawConfiguration: true,
});
export type FixedConstructionLoan = z.infer<typeof FixedConstructionLoan>;

export const ArmRoundingType = z.enum(['up', 'down', 'nearest']);
export type ArmRoundingType = z.infer<typeof ArmRoundingType>;

export const ArmLoan = BaseLoan.extend({
  paymentType: z.literal('arm'),
  productType: z.enum(['fha', 'va', 'usda', 'pih', 'conv', 'bridge', 'businessPurpose', 'jumbo']).optional(),
  fixedPeriodConfig: z.object({
    annualInterestRate: z.number(), // this captures the initial interest rate
    loanTerm: z.number().optional(), // number of months
  }),
  annualInterestRate: z.number(), // original interest rate
  armIndex: IndexRateType.optional(),
  margin: z.number().optional(), // 50 bps should be represented as 0.005
  maxIncreasePerPeriod: z.number().optional(), // 50 bps should be represented as 0.005
  minIncreasePerPeriod: z.number().optional(), // 50 bps should be represented as 0.005
  maxIncreaseForFirstPeriod: z.number().optional(), // 50 bps should be represented as 0.005
  minIncreaseForFirstPeriod: z.number().optional(), // 50 bps should be represented as 0.005
  rateCeiling: z.number().optional(), // 5% should be represented as 0.05
  rateFloor: z.number().optional(), // 5% should be represented as 0.05
  rateAdjustmentFrequency: z.number().optional(), // in months
  adjustmentLeadCountDays: z.number().optional(),
  roundingType: ArmRoundingType.optional(),
  // TODO: we actually have been storing this as a decimal... sigh. This should be relabeled
  roundingPercent: z.number().optional(),
  constructionConfig: LoanConstructionConfig.optional(),
  drawConfiguration: DrawConfiguration.optional(),
});
export type ArmLoan = z.infer<typeof ArmLoan>;

export const HelocLoan = BaseLoan.extend({
  paymentType: z.enum(['fixed']),
  productType: z.literal('heloc'),
  drawConfiguration: DrawConfiguration,
  annualInterestRate: z.number(), // represented as 0.035 for 3.5%
});
export type HelocLoan = z.infer<typeof HelocLoan>;

export const HelocArmLoan = ArmLoan.extend({
  productType: z.literal('heloc'),
  drawConfiguration: DrawConfiguration,
});
export type HelocArmLoan = z.infer<typeof HelocArmLoan>;

export const ArmConstructionLoan = ArmLoan.extend({
  constructionConfig: LoanConstructionConfig,
  drawConfiguration: DrawConfiguration,
});
export type ArmConstructionLoan = z.infer<typeof ArmConstructionLoan>;

export const ArmEnabledLoanConfig = z.union([ArmLoan, HelocArmLoan]);
export type ArmEnabledLoanConfig = z.infer<typeof ArmEnabledLoanConfig>;

export const LoanConfiguration = z.union([
  FixedLoan,
  ArmLoan,
  HelocLoan,
  HelocArmLoan,
  FixedConstructionLoan,
  ArmConstructionLoan,
]);
export type LoanConfiguration = z.infer<typeof LoanConfiguration>;

export const AmortizationType = z.enum(['fixed', 'arm']);
export type AmortizationType = z.infer<typeof AmortizationType>;

export const isHelocLoan = (productType: ProductType) => productType === 'heloc';

export const isHelocLoanConfig = (loanConfiguration: Loan['configuration']): loanConfiguration is HelocLoan =>
  loanConfiguration.productType === 'heloc';

export const isConstructionLoan = (constructionLoanType: ConstructionLoanType | undefined): boolean =>
  constructionLoanType === 'constructionOnly' || constructionLoanType === 'constructionToPermanent';

export const isActiveConstructionLoan = (
  constructionLoanType: ConstructionLoanType | undefined,
  constructionToPermanentStatus: ConstructionToPermanentStatus | undefined,
): boolean =>
  constructionLoanType === 'constructionOnly' ||
  (constructionLoanType === 'constructionToPermanent' && constructionToPermanentStatus === 'active');

export const isPermanentConstructionLoan = (
  constructionLoanType: ConstructionLoanType | undefined,
  effectiveDate: Date | undefined,
  targetDate: Date,
): boolean =>
  constructionLoanType === 'constructionToPermanent' &&
  !!effectiveDate &&
  DateTime.fromJSDate(effectiveDate).startOf('day').toJSDate() <=
    DateTime.fromJSDate(targetDate).startOf('day').toJSDate();

/* This returns true if the loan has draw configuration, and does not necessarily have to be enabled at this moment.
   This is useful for things like displaying past draws on the frontend.
*/
export const isDrawableLoanConfig = (loanConfiguration: Loan['configuration']): boolean =>
  isHelocLoanConfig(loanConfiguration) ||
  isConstructionLoanConfig(loanConfiguration) ||
  (loanConfiguration.drawConfiguration?.maxLineOfCredit ?? 0) > 0;

export const isConstructionLoanConfig = (
  loanConfiguration: Loan['configuration'],
): loanConfiguration is FixedConstructionLoan =>
  'constructionConfig' in loanConfiguration && !!loanConfiguration.constructionConfig;

export const isArmEnabledLoanConfig = (
  loanConfiguration: Loan['configuration'],
): loanConfiguration is ArmEnabledLoanConfig => loanConfiguration.paymentType === 'arm';

export const isNonConvertedArmEnabledLoanConfig = (
  loanConfiguration: Loan['configuration'],
  constructionConfig: ConstructionConfig | undefined,
  targetDate: Date,
): boolean =>
  loanConfiguration.paymentType === 'arm' &&
  !(
    constructionConfig &&
    isPermanentConstructionLoan(
      loanConfiguration.constructionConfig?.constructionLoanType,
      constructionConfig.constructionModificationEffectiveDate,
      targetDate,
    )
  );

export const isDutch = (loanConfiguration: Loan['configuration']): boolean =>
  isConstructionLoanConfig(loanConfiguration) && loanConfiguration.constructionConfig.dutch;

export const isInterestOnly = (loanConfiguration: Loan['configuration']): boolean =>
  !!loanConfiguration.interestOnlyConfig;
